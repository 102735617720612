import React, { useRef } from 'react';
import css from './SectionRecentlyViewed.module.css';
import { array } from 'prop-types';
import { FormattedMessage } from '../../../util/reactIntl';
import RecentlyViewedCard from './RecentlyViewedCard';
import { Swiper, SwiperSlide } from 'swiper/react';
import { IconArrowHead } from '../../../components';

import SwiperCore, { Navigation } from 'swiper/core';

SwiperCore.use([Navigation]);

const SectionRecentlyViewed = props => {
  const { recentlyListings } = props;
  const prevElRef = useRef(null);
  const nextElRef = useRef(null);
  const convertedRecentlyListings = recentlyListings.map(recentlyItem => ({
    ...recentlyItem.author,
    ...recentlyItem.attributes,
    ...recentlyItem.id,
    image: recentlyItem.images ? recentlyItem.images[0] : '',
  }));

  const getSlidesPerView = (desiredCount) => {
    return Math.min(convertedRecentlyListings?.length || 1, desiredCount);
  };

  return (
    <div className={css.sectionRecentlyListings}>
      <h2 className={css.sectionRecentlyListingsTitle}>
        <FormattedMessage id="SectionRecentlyViewed.title" />
      </h2>
      <div className={css.sectionRecentlyListingsSlider}>
        <Swiper
          className={css.sectionRecentlyListingsSwiper}
          direction={'horizontal'}
          spaceBetween={80}
          slidesPerView={4}
          breakpoints={{
            320: { slidesPerView: getSlidesPerView(1) },
            576: { slidesPerView: getSlidesPerView(2) },
            992: { slidesPerView: getSlidesPerView(3) },
            1200: { slidesPerView: getSlidesPerView(4) }
          }}
          grabCursor={true}
          onInit={swiper => {
            if (
              swiper &&
              swiper.params &&
              typeof swiper.params.navigation === 'object' &&
              typeof swiper.navigation === 'object'
            ) {
              swiper.params.navigation.prevEl = prevElRef.current;
              swiper.params.navigation.nextEl = nextElRef.current;
              swiper.navigation.update();
            }
          }}
        >
          {convertedRecentlyListings.length ? (
            convertedRecentlyListings.map(listing => {
              return (
                <SwiperSlide key={listing.uuid}>
                  <RecentlyViewedCard listingData={listing} />
                </SwiperSlide>
              );
            })
          ) : (
            <></>
          )}
          <button className={css.sliderPrev} ref={prevElRef}>
            <IconArrowHead direction="left" size="big" rootClassName={css.arrowIcon} />
          </button>
          <button className={css.sliderNext} ref={nextElRef}>
            <IconArrowHead direction="right" size="big" rootClassName={css.arrowIcon} />
          </button>
        </Swiper>
      </div>
    </div>
  );
};

SectionRecentlyViewed.defaultProps = {
  recentlyListings: [],
  recentlyImages: [],
};

SectionRecentlyViewed.propTypes = {
  recentlyListings: array.isRequired,
  recentlyImages: array.isRequired,
};

export default SectionRecentlyViewed;
