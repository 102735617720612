import { subcategory } from '../config/subcategory';

export function getBreadCrumbsData() {
  const categories = {
    casegoods_and_storage: 'Casegoods & Storage',
    beds: 'Beds',
    seating: 'Seating',
    tables: 'Tables',
  };

  const allSubCategory = subcategory
    .map(el => {
      return el.config.options.map(option => ({
        [option.key]: option.label,
      }));
    })
    .flat();

  return {
    categories,
    allSubCategory,
  };
}
