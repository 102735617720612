export const subcategory = [
  {
    id: 'bedsubcategory',
    label: 'Bed SubCategory',
    type: 'SelectMultipleFilter',
    group: 'primary',
    foriegn: 'beds',
    queryParamNames: ['pub_subcategory'],
    config: {
      // Schema type is enum for SelectSingleFilter
      schemaType: 'enum',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        // { key: 'all_beds', label: 'All Beds'},
        { key: 'bed_frames', label: 'Bed Frames' },
        // { key: 'bedroom_set', label: 'Bedroom Sets'},
        { key: 'canopy_beds', label: 'Canopy Beds' },
        { key: 'headboards', label: 'Headboards' },
        // { key: 'other', label: 'Other' },
      ],
    },
  },
  {
    id: 'casegoodsubcategory',
    label: 'Casegoods SubCategory',
    type: 'SelectMultipleFilter',
    group: 'primary',
    foriegn: 'casegoods_and_storage',
    queryParamNames: ['pub_subcategory'],
    config: {
      // Schema type is enum for SelectSingleFilter
      schemaType: 'enum',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        // { key: 'all_casegoods', label: 'All Casegoods & Storage'},
        { key: 'armoires_wardrobes', label: 'Armoires & Wardrobes' },
        { key: 'bookcases', label: 'Bookcases' },
        // { key: 'bed_cart', label: 'Bed Carts'},
        { key: 'china_display_cabinets', label: 'China & Display Cabinets' },
        // { key: 'console_tables', label: 'Console Tables'},
        { key: 'credenzas', label: 'Credenzas' },
        { key: 'dresser_chest_drawers', label: 'Dressers & Chests of Drawers' },
        { key: 'etageres', label: 'Etageres' },
        // { key: 'storage_cabinet' , label:'Storage Cabinets' },
        // { key: 'trunk_chest', label: 'Trunks & Chests'},
        { key: 'vanities', label: 'Vanities' },
        { key: 'nightstands', label: 'Nightstands' },
        // { key: 'other', label: 'Other' },
      ],
    },
  },

  {
    id: 'seatingsubcategory',
    label: 'Seating SubCategory',
    type: 'SelectMultipleFilter',
    group: 'primary',
    foriegn: 'seating',
    queryParamNames: ['pub_subcategory'],
    config: {
      // Schema type is enum for SelectSingleFilter
      schemaType: 'enum',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        // { key: 'all_seating', label: 'All Seating' },
        { key: 'accent_chair', label: 'Accent Chairs' },
        { key: 'benches', label: 'Benches' },
        { key: 'barstools', label: 'Barstools' },
        { key: 'chair_set', label: 'Chair Sets' },
        { key: 'dining_chair', label: 'Dining Chairs' },
        { key: 'ottomans_footstools', label: 'Ottomans & Footstools' },
        // { key: 'rocking_chairs', label: 'Rocking Chairs' },
        // { key: 'sofas', label: 'Sofas' },
        // { key: 'stools', label: 'Stools' },
        // { key: 'other', label: 'Other' },
      ],
    },
  },

  {
    id: 'tablesubcategory',
    label: 'Tables SubCategory',
    type: 'SelectMultipleFilter',
    group: 'primary',
    foriegn: 'tables',
    queryParamNames: ['pub_subcategory'],
    config: {
      // Schema type is enum for SelectSingleFilter
      schemaType: 'enum',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        // { key: 'all_tables', label: 'All Tables' },
        { key: 'accent_tables', label: 'Accent Tables' },
        { key: 'coffee_table', label: 'Coffee Table' },
        { key: 'desks', label: 'Desks' },
        // { key: 'dining_sets', label: 'Dining Sets' },
        { key: 'dining_table', label: 'Dining Table' },
        { key: 'console_table', label: 'Console Tables' },
        // { key: 'other', label: 'Other' },
      ],
    },
  },
];
