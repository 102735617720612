import React, { useMemo } from 'react';
import { object } from 'prop-types';
import { NamedLink, ResponsiveImage } from '../../../components';
import css from './RecentlyViewedCard.module.css';
import { createSlug } from '../../../util/urlHelpers';
import config from '../../../config';
import { formatMoney } from '../../../util/currency';
import { types as sdkTypes } from '../../../util/sdkLoader';
import { injectIntl } from '../../../util/reactIntl';
import classNames from 'classnames';
const { Money } = sdkTypes;

const RECENTLY_IMAGE_VARIANTS = [
  // 400x400
  'listing-card',
  // 800x800
  'listing-card-2x',
];

const RecentlyViewedCard = props => {
  const { listingData, intl } = props;

  const priceData = (price, intl) => {
    if (price && price.currency === config.currency) {
      const formattedPrice = formatMoney(intl, price);
      return { formattedPrice, priceTitle: formattedPrice };
    } else if (price) {
      return {
        formattedPrice: intl.formatMessage(
          { id: 'ListingCard.unsupportedPrice' },
          { currency: price.currency }
        ),
        priceTitle: intl.formatMessage(
          { id: 'ListingCard.unsupportedPriceTitle' },
          { currency: price.currency }
        ),
      };
    }
    return {};
  };

  if (!listingData || !Object.keys(listingData).length) {
    return null;
  }

  const { uuid: id, title, image, price, publicData } =
    Object.keys(listingData).length && listingData;
  const { isSale, oldPrice } = publicData || {};

  const { formattedPrice, priceTitle } = priceData(price, intl);

  const { profile } = listingData?.attributes || {};
  const oldPriceMoney = isSale && oldPrice ? new Money(oldPrice.amount, oldPrice.currency) : null;
  const { formattedPrice: formattedOldPrice } =
    oldPriceMoney && price.amount < oldPriceMoney.amount ? priceData(oldPriceMoney, intl) : {};
  const discountPercent = formattedOldPrice
    ? Math.round((1 - price.amount / oldPriceMoney.amount) * 100)
    : null;

  const slug = createSlug(title);
  const renderSizes = '300px';

  return (
    <div className={css.ListingCard}>
      <div className={css.ListingCardImageWrapper}>
        {image && (
          <NamedLink name="ListingPage" params={{ id, slug }}>
            <ResponsiveImage
              rootClassName={css.ListingCardImage}
              alt={title}
              image={image}
              variants={RECENTLY_IMAGE_VARIANTS}
              sizes={renderSizes}
            />
          </NamedLink>
        )}
      </div>
      <div className={css.ListingCardContent}>
        <div className={css.priceWrapper}>
          <div className={css.price}>
            {formattedOldPrice ? (
              <div className={classNames(css.priceValue, css.oldPriceValue)} title={priceTitle}>
                {formattedOldPrice}
              </div>
            ) : (
              <></>
            )}
            <div className={css.priceValue} title={priceTitle}>
              {formattedPrice}
            </div>
          </div>
          {discountPercent ? <div className={css.discount}>-{discountPercent}%</div> : <></>}
        </div>
        <h5 className={css.ListingCardTitle}>{title}</h5>
        <p className={css.ListingCardText}>Sold by {profile?.displayName}</p>
      </div>
    </div>
  );
};

RecentlyViewedCard.defaultProps = {
  listingData: {},
};

RecentlyViewedCard.propTypes = {
  listingData: object,
};

export default injectIntl(RecentlyViewedCard);
